// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-stream-jsx": () => import("./../../../src/pages/stream.jsx" /* webpackChunkName: "component---src-pages-stream-jsx" */),
  "component---src-pages-stream-subscribe-jsx": () => import("./../../../src/pages/stream-subscribe.jsx" /* webpackChunkName: "component---src-pages-stream-subscribe-jsx" */),
  "component---src-pages-stream-unsubscribe-jsx": () => import("./../../../src/pages/stream-unsubscribe.jsx" /* webpackChunkName: "component---src-pages-stream-unsubscribe-jsx" */),
  "component---src-pages-tags-jsx": () => import("./../../../src/pages/tags.jsx" /* webpackChunkName: "component---src-pages-tags-jsx" */),
  "component---src-pages-terms-and-conditions-jsx": () => import("./../../../src/pages/terms-and-conditions.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-jsx" */),
  "component---src-pages-test-jsx": () => import("./../../../src/pages/test.jsx" /* webpackChunkName: "component---src-pages-test-jsx" */),
  "component---src-templates-review-jsx": () => import("./../../../src/templates/review.jsx" /* webpackChunkName: "component---src-templates-review-jsx" */),
  "component---src-templates-reviews-list-template-jsx": () => import("./../../../src/templates/reviews-list-template.jsx" /* webpackChunkName: "component---src-templates-reviews-list-template-jsx" */),
  "component---src-templates-tags-jsx": () => import("./../../../src/templates/tags.jsx" /* webpackChunkName: "component---src-templates-tags-jsx" */)
}

