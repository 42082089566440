exports.onClientEntry = () => {
  require.ensure(['@sentry/browser'], (require) => {
    const Sentry = require('@sentry/browser');
    Sentry.init({
      dsn: 'https://2759845ce318453abd5486c4c0a625b8@sentry.io/1807685',
      enabled: true,
    });
    window.Sentry = Sentry;
  });
};
